import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Navbar, NavbarBrand, NavbarToggler } from 'reactstrap';
import { config } from '../environment/config';
import RtgLogo from './Logo/RtgLogo';
import './NavMenu.css';

export class NavMenu extends Component {
  static displayName = NavMenu.name;

  constructor(props) {
    super(props);
    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true
    };
  }

  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  render() {
    return (
      <header>
        <Navbar className="navbar-expand-sm navbar-toggleable-sm sticky-top ng-white border-bottom box-shadow mb-3"
          fixed="top"
          light
        >
          <NavbarBrand tag={Link} to="/">
                    <RtgLogo config={config} />
          </NavbarBrand>
          <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
        </Navbar>
      </header>
    );
  }
}
