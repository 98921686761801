import React, { Component } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Layout } from './components/Layout';
import './custom.css';
import ProcessServiceClaimForm from './components/ProcessServiceClaimForm';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { AppInsightsErrorBoundary } from "@microsoft/applicationinsights-react-js";
import { config } from './environment/config';

const connection = config.appInsights.connectionString
const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
    config: {
        connectionString: connection,
        extensions: [reactPlugin],
        enableAutoRouteTracking: false,
        disableAjaxTracking: false,
        autoTrackPageVisitTime: true,
        enableCorsCorrelation: true,
        enableRequestHeaderTracking: true,
        enableResponseHeaderTracking: true
    }
});
appInsights.loadAppInsights();

export default class App extends Component {
  static displayName = App.name;

  render() {
    return (
      <Layout>
            <AppInsightsErrorBoundary onError={() => <div>Something went wrong, please give us a call at 1 (800) 766-6786.</div>} appInsights={reactPlugin}>
                <AppInsightsContext.Provider value={reactPlugin}>
                    <Routes>
                        <Route path="/serviceclaim/:service/:items" element={<ProcessServiceClaimForm />} action={appInsights.trackPageView({ name: 'process claim approval form' })} />
                    </Routes>
                </AppInsightsContext.Provider>
            </AppInsightsErrorBoundary>
      </Layout>
    );
  }
}
