import React from 'react';
import styled from 'styled-components';

const StyledImg = styled.img`
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 2px;
`;

const RtgLogo = (props) => {
    return (
        <StyledImg src={props.config.rtgCareLogo.url} width={props.config.rtgCareLogo.width} />
    );
}

export default RtgLogo;