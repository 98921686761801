import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Input, Form, FormGroup, Label, Alert, FormFeedback, Spinner } from 'reactstrap'
import { useParams } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment/moment';
import { config } from '../environment/config';
import useAxios from 'axios-hooks';
import { FaCheckToSlot } from "react-icons/fa6";
import { IconContext } from "react-icons";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { SeverityLevel } from '@microsoft/applicationinsights-web';

const ServiceClaimModes = {
    Approved: 0,
    Denied: 1,
    PartiallyDenied: 2
}

const ProcessServiceClaimForm = (props) => {
    const appInsights = useAppInsightsContext();
    const [serviceNumber, setServiceNumber] = useState('');
    const [serviceId, setServiceId] = useState(0);
    const [serviceDate, setServiceDate] = useState('');
    const [serviceItems, setServiceItems] = useState([]);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [error, setError] = useState('');
    const [isBusy, setIsBusy] = useState(false);
    let { service, items } = useParams();

    const [
        { data: serviceItemDetails, loading: serviceItemsLoading, error: serviceItemsError },
        getServiceItemDetails
    ] = useAxios({
        url: `${config.api.baseURL}api/Claims/GetServiceItemDetails`,
        headers : { "rtg-claims-header" : config.api.rtgClaimsHeader },
        method: "GET"
    }, {
        manual: true
    })

    useEffect(() => {
        if (service && items) {
            appInsights.trackTrace({ message: "Loading claim data", severityLevel: SeverityLevel.Information });
            getServiceItemDetails({
                params: {
                    serviceId: service,
                    itemIds: items
                }
            });
        }
    }, [service, items, getServiceItemDetails, appInsights])

    useEffect(() => {
        if (serviceItemsError) {
            appInsights.trackException({
                exception: new Error('User encountered an error while loading the service claim form.', {cause: 'Failure in Web API'})
            });
        }
    })

    useEffect(() => {
        if (serviceItemDetails) {
            setServiceNumber(serviceItemDetails.ServiceNumber);
            setServiceDate(moment(serviceItemDetails.ServiceDate).format('YYYY-MM-DD'));
            setServiceId(serviceItemDetails.ESCQueueServiceId);

            const items = serviceItemDetails.ServiceItems.map(item => {
                return {
                    id: item.ESCQueueItemId,
                    sku: item.SkuNumber,
                    description: item.SkuDescription,
                    warrantyAuthNumber: item.ESCAuthorizationNumber,
                    denialStatus: null,
                    denialReason: ''
                }
            })

            setServiceItems(items);
        }
    }, [serviceItemDetails])

    const trackSubmitForm = (formBody) => appInsights.trackEvent({ name: 'Submit Provider Approval Form', properties: formBody });


    const handleSubmitButtonClick = () => {
        if (!validateForm()) {
            return;
        }

        const claimMode = determineServiceClaimMode();
        const request = buildUrlAndBody(claimMode);

        trackSubmitForm(request.body);
        setIsBusy(true)
        axios.post(request.url,
                request.body, {
                headers: { "rtg-claims-header": config.api.rtgClaimsHeader }
            })
            .then(response => {
                if (response.status === 200) {
                    setFormSubmitted(true);
                }
            })
            .catch(err => {
                setError(err.message);
            })
            .finally(() => {
                setIsBusy(false)
            })
    }

    const buildUrlAndBody = (claimMode) => {
        let request = {
            url: `${config.api.baseURL}api/Claims`,
            body: {
                serviceId: serviceId,
                mode: claimMode
            }
        }

        //if (claimMode !== ServiceClaimModes.Approved) {
            request.body.items = serviceItems
                .map(i => (
                    {
                        itemId: i.id,
                        denialReason: i.denialReason,
                        approvalComments: i.approvalComments
                    }))
        //}

        return request;
    }

    // Validates form
    // 1 - Denied Service Items must contain a denial reason
    const validateForm = () => {
        const missingDenialReasons = serviceItems.filter(item => item.denialStatus === true && item.denialReason.length === 0);
        let errorMessage = "";
        let isValid = true;
        if (missingDenialReasons.length > 0) {
            errorMessage = "Complete the Denial Reason for all denied Service Items.";
            isValid = false;
        }
        // check all the items for denialStatus
        const missingStatus = serviceItems.filter(item => item.denialStatus === null);
        if (missingStatus.length > 0) {
            if (errorMessage.length > 0) {
                errorMessage += " ";
            }
            errorMessage += "Select Approve or Deny for all Service Items."
            isValid = false;
        }
        if (!isValid) {
            setError(errorMessage);
        }
        return isValid;
    }

    // Determines if the mode is Approve, Deny, or Partially Deny
    const determineServiceClaimMode = () => {
        const serviceItemsCount = serviceItems.length;
        const deniedServiceItemsCount = serviceItems.filter(item => item.denialStatus === true).length;

        if (deniedServiceItemsCount === 0) {
            return ServiceClaimModes.Approved;
        }
        else if (serviceItemsCount === deniedServiceItemsCount) {
            return ServiceClaimModes.Denied;
        }
        else {
            return ServiceClaimModes.PartiallyDenied;
        }
    }

    const handleDenialReasonChanged = (reason, index) => {
        let newServiceItems = [...serviceItems];
        newServiceItems[index].denialReason = reason;
        setServiceItems(newServiceItems);
    }

    const handleApprovalCommentsChanged = (comments, index) => {
        let newServiceItems = [...serviceItems];
        newServiceItems[index].approvalComments = comments;
        setServiceItems(newServiceItems);
    }

    const renderServiceItems = () => {
        return serviceItems.map((item, index) => {
            const radios = [
                { name: "Approve", value: false },
                { name: "Deny", value: true }
            ]

            return (
                <Col key={index} md="6">
                    <div className="mb-5 border rounded p-2">
                        <Row>
                            <Col md="4">
                                <Label>Sku Number</Label>
                            </Col>
                            <Col md="8">
                                <div className='float-end'>
                                    {radios.map((radio, radioIndex) => {
                                        return (
                                            <FormGroup check inline key={radioIndex}>
                                                <Input
                                                    type="radio"
                                                    value={radio.value}
                                                    checked={item.denialStatus === radio.value}
                                                    onChange={e => {
                                                        let newServiceItems = [...serviceItems];
                                                        newServiceItems[index].denialStatus = e.target.value === "true" ? true : false;
                                                        setServiceItems(newServiceItems);
                                                    }} />
                                                <Label>{radio.name}</Label>
                                            </FormGroup>
                                        )
                                    })}
                                </div>
                            </Col>
                        </Row>
                        <Input
                            value={item.sku}
                            disabled={true}
                        />
                        <Col className="my-2">Description</Col>
                        <Input
                            value={item.description}
                            disabled={true}
                        />
                        <Col className="my-2">Auth Number</Col>
                        <Input
                            value={item.warrantyAuthNumber}
                            disabled={true}
                        />
                        {item.denialStatus &&
                            <FormGroup className="mt-2">
                                <Label for="denialReason">Denial Reason</Label>
                                <Input
                                    id="denialReason"
                                    type="textarea"
                                    value={item.denialReason}
                                    onChange={(e) => handleDenialReasonChanged(e.target.value, index)}
                                    maxLength={500}
                                    invalid={item.denialReason.length === 0}
                                />
                                <FormFeedback>
                                    Denial Reason cannot be empty.
                                </FormFeedback>
                            </FormGroup>
                        }
                        {item.denialStatus === false &&
                            <FormGroup className="mt-2">
                                <Label for="approvalComments">Approval Comments</Label>
                                <Input
                                    id="approvalComments"
                                    type="textarea"
                                    value={item.approvalComments}
                                    onChange={(e) => handleApprovalCommentsChanged(e.target.value, index)}
                                    maxLength={500}
                                />
                            </FormGroup>
                        }
                    </div>
                </Col>
            )
        })
    }

    return (
        <Container className="my-3">
            <h2>Process Service Claim</h2>

            {error &&
                <Alert color="danger" toggle={() => setError('')}>{error}</Alert>
            }

            {serviceItemsError &&
                <Alert color="danger">We can't load that service, please call our Customer Care Team.</Alert>
            }
            {serviceItemsLoading ?
                <Spinner className="m-5"/>
                :
                <>
                    {!formSubmitted && !serviceItemsError &&
                        <Form className="border rounded p-3">
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Label>Service Number</Label>
                                        <Input
                                            value={serviceNumber}
                                            disabled={true}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup className="mb-3">
                                        <Label>Date of Service</Label>
                                        <Input
                                            type="datetime"
                                            value={serviceDate}
                                            disabled={true}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>

                            <h4 className="mt-3">Service Items</h4>

                            {serviceItems && serviceItemDetails &&
                                <Row>
                                    {renderServiceItems()}
                                </Row>
                            }
                            <Button
                                color="primary"
                                onClick={handleSubmitButtonClick}
                                disabled={isBusy}
                            >
                                Submit
                            </Button>
                        </Form>
                    }
                </>
            }

            {formSubmitted &&
                <Row xs="6">
                    <Col xs="6">
                        <Alert className="">
                            <IconContext.Provider value={{ className: "shared-class", size: 70 }}>
                                <>
                                    <FaCheckToSlot />
                                </>
                            </IconContext.Provider>
                            <hr />
                            Your response has been successfully submitted! Please close this window.
                        </Alert>                    
                    </Col>
                </Row>
            }
        </Container >
    )
}

export default ProcessServiceClaimForm