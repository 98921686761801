export const config = {
    api: {
        baseURL: process.env.REACT_APP_API_BASE_URL,
        rtgClaimsHeader: process.env.REACT_APP_CLAIMS_HEADER
    },
    rtgCareLogo: {
        url: process.env.REACT_APP_RTG_CARE_LOGO_URL,
        width: process.env.REACT_APP_RTG_CARE_LOGO_WIDTH
    },
    appInsights: {
        connectionString: process.env.REACT_APP_APP_INSIGHTS_CONNECTION
    }
}